import { animated, useSpring } from "react-spring";
import Accordion from "../Components/Accordion";

const FAQ = () => {
  const titleAnimation = useSpring({
    from: {
      textAlign: "center",
      color: "var(--white)",
      transform: "translateY(-120px)",
    },
    to: [
      {
        textAlign: "center",
        color: "var(--white)",
        transform: "translateY(15px)",
      },
    ],
    config: { mass: 3, tension: 300, friction: 25 },
  });

  return (
    <div
      className="container"
      id="faq"
      style={{ marginTop: 100, scrollMarginTop: 150, marginBottom: 100 }}
    >
      <div>
        <animated.h1 style={titleAnimation}>FAQ</animated.h1>
        <div className="row" style={{ marginTop: 40 }}>
          <div className="accordion">
            <Accordion
              title="How Many Fruity Fools will be available on the first launch?"
              text="We have decided to come up with a collection of 10,000."
            />
            <Accordion
              title="How much will it cost to mint a Fruity Fool?"
              text=".15 ETH"
            />
            <Accordion
              title="How much will the Whitelist price be?"
              text="0.08 ETH"
            />
            <Accordion
              title="How Many Fruity Fools can I mint?"
              text="As many as you like, we understand its hard to avoid making Foolish decisions when it comes to our Fruity Fools launch."
            />
            <Accordion
              title="Where Can I mint a Fruity Fool?"
              text="You can simply mint on our website."
            />
            <Accordion
              title="What chain is Fruity Fools hosted on?"
              text="Fruity Fools will be on the Etheruem Blockchain."
            />
            <Accordion
              title="Are Fruity Fools hand drawn or generated?"
              text="Fruity Fools has gotten their inspiration from a member of the team drawing the original concept and painting the concept on a Canvas the very first take on a Fruity Fools concept. The 10,000 pieces have been carefully designed, developed and generated. With their own uniqueness."
            />
            <Accordion title="When is the release date?" text="Coming Soon." />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
