import React from "react";
import { Parallax } from "react-scroll-parallax";
import useWindowDimensions from "../hooks/useWindowDimensions";
import aboutUsGif from "../assets/about_us.gif";

const AboutUs = () => {
  const { width } = useWindowDimensions();

  return (
    <Parallax translateX={width > 600 && ["-8%", "5%"]}>
      <div
        id="about"
        style={{
          position: "relative",
          marginBottom: width < 600 ? 120 : 240,
          marginTop: 120,
          scrollMarginTop: 175,
        }}
        className="container"
      >
        <div
          className="row"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            padding: 40,
            borderRadius: 10,
          }}
        >
          <div className="col-lg">
            <h1 style={{ color: "var(--white)" }}>About Us</h1>
            <br />
            <p style={{ color: "var(--white)", fontSize: 20 }}>
              The Fruity Fools collection is being introduced to the NFT
              community by a team of enthusiasts by taking our own comedic edge
              on Fruit becoming characterized. With the goal of bringing out the
              inner child of our community members we intend on creating art
              collections of Fruity Fools as bragging rights for our Holders.
              <br />
              <br />
              Fruity Fools plans to launch with a variety of collections across
              all other commodities and giving a unique spin on each one. Fruity
              Fools team is committed to adding value to all holders. Fruity
              Fools will aim to acquire properties for the purpose of conducting
              an AirBnb business. (See roadmap for details). Furthermore access
              to the properties for holders will be issued periodically
              throughout the year via raffle giveaways.
              <br />
              <br />
              Fruity Fools will also be giving away a Tesla Model 3 and making a
              Charity Contribution chosen by our Discord community. We will also
              host seminars with Airbnb professionals who are successful
              entrepreneurs in the space for our community to learn how to get
              into the AirBnb business. This launch is more than a destination,
              it is a path for a fruitful future.
            </p>
          </div>
          <div className="col-sm" style={{ margin: "auto 0" }}>
            <img alt="about-us-img" className="about-us-img" src={aboutUsGif} />
          </div>
        </div>
      </div>
    </Parallax>
  );
};

export default AboutUs;
