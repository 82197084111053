import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { animated, useSpring } from "react-spring";

import goldApple from "../assets/gold-apple.png";
import pinkApple from "../assets/pink-apple.png";

import twentyFivePercent from "../assets/25.png";
import fiftyPercent from "../assets/50.png";
import seventyFivePercent from "../assets/75.png";
import hundredPercent from "../assets/100.png";
import useWindowDimensions from "../hooks/useWindowDimensions";

const Roadmap = () => {
  const { width } = useWindowDimensions();
  const titleAnimation = useSpring({
    from: {
      textAlign: "center",
      color: "var(--white)",
      transform: "translateY(-100px)",
    },
    to: [
      {
        textAlign: "center",
        color: "var(--white)",
        transform: "translateY(15px)",
      },
    ],
    config: { mass: 3, tension: 500, friction: 25 },
  });

  return (
    <div
      id="roadmap"
      className="bg-back"
      style={{ paddingTop: 40, paddingBottom: 60, scrollMarginTop: 122 }}
    >
      <div style={{ marginBottom: 80 }}>
        <animated.h1 style={titleAnimation}>Roadmap</animated.h1>
        <br />
        <center>
          <h5 style={{ width: "50vw" }}>
            Fruity Fools is a full-time project, we will continue to grow the
            Airbnb business by acquiring more properties and building a
            portfolio of assets for the future. We will achieve this with future
            drops of other fruit commodities as NFT collections. Charity
            contributions will be ongoing and the giveaways will continue!
            <br />
            <br />
            Listed below is what we're working towards in the short term. Future
            developments will be announced in Roadmap 2.0.
            <br />
            <br />
            Pre Launch - We will be giving away a trip for 2 to Mexico,
            flights/hotels covered for the week. Dates are flexible to
            accommodate the winner’s timeline and schedule. This trip giveaway
            will be determined by the top inviter on Discord.
          </h5>
        </center>
      </div>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{
            background:
              "linear-gradient(90deg, rgba(41,228,229,1) 0%, rgba(41,112,219,1) 100%)",
            color: "#fff",
            border: "1px solid var(--white)",
          }}
          iconStyle={{ background: "var(--dark)", color: "#fff" }}
          icon={
            <img alt="" src={twentyFivePercent} style={{ height: "100%" }} />
          }
        >
          <p style={{ color: "var(--dark)" }}>
            - Fruity Fools, will commit to raising brand awareness via marketing
            which will increase the floor price for holders.
            <br />
            <br />- Fruity Fools Will Giveaway 1 ETH and 1 Fruity Fools NFT to
            the top 3 Discord Members inviting new members.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{
            background:
              "linear-gradient(90deg, rgba(207,27,61,1) 0%, rgba(255,209,69,1) 100%)",
            color: "#fff",
            border: "1px solid var(--white)",
          }}
          iconStyle={{ background: "var(--dark)", color: "#fff" }}
          icon={<img alt="" src={fiftyPercent} style={{ height: "100%" }} />}
        >
          <p style={{ color: "#F9F4ED" }}>
            - Fruity Fools first give back will be a $50,000 Charity
            Contribution based on the community choice in the Discord.
            <br />
            <br />
            - Fruity Fools Will Giveaway 1 ETH and 1 Fruity Fools NFT to the top
            3 Discord Members inviting new members.
            <br />
            <br />
            - Sweep some of the floor
            <br />
            <br />
            - Giveaways will be announced and updated in Discord
            <br />
            <br />- Marketing Efforts Increase
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{
            background:
              "linear-gradient(90deg, rgba(136,0,184,1) 0%, rgba(174,111,226,1) 100%)",
            color: "#fff",
            border: "1px solid var(--white)",
          }}
          iconStyle={{ background: "var(--dark)", color: "#fff" }}
          icon={
            <img alt="" src={seventyFivePercent} style={{ height: "100%" }} />
          }
        >
          <p style={{ color: "var(--dark)" }}>
            - Fruity Fools team will begin shopping property for Airbnb business
            <br />
            <br />- Fruity Fools will issue a transaction of 2 ETH to a lucky
            holder via Discord. (Holder must be Holder verified within the
            Discord)
            <br />
            <br />- Fruity Fools Will Giveaway 1 ETH and 1 Fruity Fools NFT to
            the top 3 Discord Members inviting new members.
            <br />
            <br />- Sweep the floor Giveaways will be announced and updated in
            Discord
            <br />
            <br />- Marketing Efforts Increase
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{
            background:
              "linear-gradient(90deg, rgba(0,104,56,1) 0%, rgba(55,179,74,1) 100%)",
            color: "#fff",
            border: "1px solid var(--white)",
          }}
          iconStyle={{ background: "var(--dark)", color: "#fff" }}
          icon={<img alt="" src={hundredPercent} style={{ height: "100%" }} />}
        >
          <p style={{ color: "#EEF8F0" }}>
            - Fruity Fools team will acquire property for Airbnb business
            <br />
            <br />- Fruity fools will give away a Tesla Model 3 or the equal
            value of a Tesla Model 3 in ETH. The winner may choose.
            <br />
            <br />- Fruity Fools will set up a community wallet and issue 25 ETH
            to the wallet.
            <br />
            <br />- Fruity Fools will raffle a trip giveaway for 2 at the value
            of $10,000 to a destination of the winner's choice. The winner may
            choose to take the trip or receive the value of the trip in ETH.
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
      <div style={{ marginTop: 60 }}>
        <center>
          <h5 style={{ width: width > 900 ? "50vw" : "70vw" }}>
            {width > 900 ? (
              <div
                className="row"
                style={{
                  backgroundColor: "#c49039",
                  padding: 10,
                  borderRadius: 10,
                }}
              >
                <div className="col">
                  <img alt="" src={goldApple} style={{ width: "80%" }} />
                </div>
                <div className="col-6">
                  <br />
                  <strong>Gold Apple</strong>
                  <br />
                  <br />
                  Airbnb Profits of 20% will be issued evenly amongst all 10
                  Gold Apple holders for 2 years.
                  <hr />
                  Once we acquire the property we will announce the location
                  from where the Gold Apple Holders will receive the profits.
                  <hr />
                  Exclusive access to the property for Gold Apple Holders will
                  be available throughout the year. You will be given select
                  dates in Discord( Peak Season included ). We will create a
                  channel among the 10 Gold Apple Holders to engage and
                  communicate with around booking and availability.
                  <br />
                  <br />
                </div>
              </div>
            ) : (
              <div
                style={{
                  backgroundColor: "#c49039",
                  padding: 10,
                  borderRadius: 10,
                }}
              >
                <img alt="" src={goldApple} style={{ width: "75%" }} />
                <br />
                <br />
                <strong>Gold Apple</strong>
                <br />
                <br />
                Airbnb Profits of 20% will be issued evenly amongst all 10 Gold
                Apple holders for 2 years.
                <hr />
                Once we acquire the property we will announce the location from
                where the Gold Apple Holders will receive the profits.
                <hr />
                Exclusive access to the property for Gold Apple Holders will be
                available throughout the year. You will be given select dates in
                Discord( Peak Season included ). We will create a channel among
                the 10 Gold Apple Holders to engage and communicate with around
                booking and availability.
                <br />
                <br />
              </div>
            )}
            <br />
            {width > 900 ? (
              <div
                className="row"
                style={{
                  backgroundColor: "#c3429d",
                  padding: 10,
                  borderRadius: 10,
                }}
              >
                <div className="col">
                  <img
                    alt=""
                    src={pinkApple}
                    style={{ width: "70%", marginTop: 30 }}
                  />
                </div>
                <div className="col-6">
                  <br />
                  <strong>Pink Lady Apple</strong>
                  <br />
                  <br />
                  Airbnb Profits of 20% will be issued evenly amongst all 10
                  Pink Lady Apple holders for 2 years.
                  <hr />
                  Once we acquire the property we will announce the location
                  from where the Pink Lady Apple Holders will receive the
                  profits.
                  <hr />
                  Exclusive access to the property for Pink Lady Apple Holders
                  will be available throughout the year. You will be given
                  select dates in Discord. We will create a channel among the 10
                  Pink Lady Apple Holders to engage and communicate with around
                  booking and availability.
                  <br /> <br />
                </div>
              </div>
            ) : (
              <div
                style={{
                  backgroundColor: "#c3429d",
                  padding: 10,
                  borderRadius: 10,
                }}
              >
                <img alt="" src={pinkApple} style={{ width: "70%" }} />
                <br />
                <br />
                <strong>Pink Lady Apple</strong>
                <br />
                <br />
                Airbnb Profits of 20% will be issued evenly amongst all 10 Pink
                Lady Apple holders for 2 years.
                <hr />
                Once we acquire the property we will announce the location from
                where the Pink Lady Apple Holders will receive the profits.
                <hr />
                Exclusive access to the property for Pink Lady Apple Holders
                will be available throughout the year. You will be given select
                dates in Discord. We will create a channel among the 10 Pink
                Lady Apple Holders to engage and communicate with around booking
                and availability.
                <br /> <br />
              </div>
            )}
            <br />
            <div
              className="row"
              style={{
                backgroundColor: "#1e9142",
                padding: 30,
                textAlign: "center",
                borderRadius: 10,
              }}
            >
              <center>
                <br />
                For the rest of the community, we will be issuing raffle
                giveaways to access the property.
                <hr />
                We will organize seminars by Airbnb experts to help and educate
                our community about the Airbnb business.
                <hr />
                We will host meetups/events at these properties
                <hr />
                There will be more surprises to come along the way.
                <br /> <br />
              </center>
            </div>
            <br />
            There will be ongoing rewards for members hitting milestones in the
            discord. Make sure you stay engaged throughout out the discord.
            <br />
            <br />
            <span
              style={{
                letterSpacing: 2,
                fontSize: 14,
                fontWeight: "bold",
              }}
            >
              ALL OFFICIAL LINKS ARE IN THE DISCORD
            </span>
          </h5>
        </center>
      </div>
    </div>
  );
};

export default Roadmap;
