import { React, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSpring, animated } from "react-spring";
import "../styles/accordion.css";

function Accordion(props) {
  const [open, setOpen] = useState(false);

  let toggleHandler = (e) => {
    setOpen(!open);
  };

  const openAnimation = useSpring({
    from: { opacity: "0", maxHeight: "20px" },
    to: { opacity: "1", maxHeight: open ? "300px" : "0px" },
    config: { duration: "250" },
  });

  const contextAnimation = useSpring({
    from: { opacity: "0", x: -20 },
    to: { opacity: open ? "1" : "0", x: open ? 0 : -20 },
    config: { duration: "250" },
  });

  const iconAnimation = useSpring({
    from: {
      transform: "rotate(0deg)",
      color: "#ffff",
    },
    to: {
      transform: open ? "rotate(180deg)" : "rotate(0deg)",
      color: open ? "var(--accent_2)" : "#fff",
    },
    config: { duration: "120" },
  });

  return (
    <animated.div className="accordion__item" style={openAnimation}>
      <div className="accordion__header" onClick={toggleHandler}>
        <h4 style={{ color: open ? "var(--accent_2)" : "#fff" }}>
          <span>{props.title}</span>
        </h4>
        <animated.i style={iconAnimation}>
          <ExpandMoreIcon />
        </animated.i>
      </div>
      <animated.p style={contextAnimation} className="accordion__content">
        {props.text}
      </animated.p>
    </animated.div>
  );
}

export default Accordion;
