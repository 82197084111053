import { Parallax } from "react-scroll-parallax";
import two from "../assets/2.png";
import three from "../assets/3.png";
import four from "../assets/4.png";
import five from "../assets/5.png";
import useWindowDimensions from "../hooks/useWindowDimensions";

const ImageParallax = () => {
  const { width } = useWindowDimensions();

  return (
    <div
      className="container"
      style={{
        height: width > 1200 ? "50vh" : "100vh",
        marginBottom: width < 400 && 150,
      }}
    >
      {width > 1200 ? (
        <div className="row">
          <div
            className="col"
            style={{
              marginRight: -80,
            }}
          >
            <Parallax translateY={["0%", "-70%"]}>
              <img alt="img" src={two} style={{ height: "260px" }} />
            </Parallax>
          </div>
          <div className="col" style={{ marginRight: -30, marginLeft: 20 }}>
            <Parallax translateY={["80%", "-80%"]}>
              <img alt="img" src={three} style={{ height: "260px" }} />
            </Parallax>
          </div>
          <div
            className="col"
            style={{
              marginRight: -60,
            }}
          >
            <Parallax translateY={["-20%", "-90%"]}>
              <img alt="img" src={four} style={{ height: "260px" }} />
            </Parallax>
          </div>
          <div className="col">
            <Parallax translateY={["100%", "-100%"]}>
              <img alt="img" src={five} style={{ height: "260px" }} />
            </Parallax>
          </div>
        </div>
      ) : (
        <div className="col">
          <center>
            <div className="col" style={{ marginBottom: 20 }}>
              <img alt="img" src={two} style={{ height: "170px" }} />
            </div>
            <div className="col" style={{ marginBottom: 20 }}>
              <img alt="img" src={three} style={{ height: "170px" }} />
            </div>
            <div className="col" style={{ marginBottom: 20 }}>
              <img alt="img" src={four} style={{ height: "170px" }} />
            </div>
            <div className="col" style={{ marginBottom: 20 }}>
              <img alt="img" src={five} style={{ height: "170px" }} />
            </div>
          </center>
        </div>
      )}
    </div>
  );
};

export default ImageParallax;
