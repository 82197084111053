import { motion } from "framer-motion";
import { animated, useSpring } from "react-spring";
import teamOne from "../assets/team_1.png";
import teamTwo from "../assets/team_2.png";
import teamThree from "../assets/team_3.png";

const Team = () => {
  const titleAnimation = useSpring({
    from: {
      textAlign: "center",
      color: "var(--white)",
      transform: "translateY(-100px)",
    },
    to: [
      {
        textAlign: "center",
        color: "var(--white)",
        transform: "translateY(15px)",
      },
    ],
    config: { mass: 3, tension: 500, friction: 25 },
  });

  return (
    <div id="team" className="container" style={{ scrollMarginTop: 140 }}>
      <div
        style={{
          marginTop: 120,
          marginBottom: 120,
        }}
      >
        <center>
          <div className="row" style={{ marginBottom: 60 }}>
            <animated.h1 style={titleAnimation}>
              Fruity Fools Family
            </animated.h1>
            <br />
            <br />
            <animated.h5 style={titleAnimation}>
              Fruity Fools Founders are family members in it together for the
              long term.
            </animated.h5>
          </div>
        </center>
        <div className="row">
          <div className="col-sm" style={{ padding: "0" }}>
            <center>
              <motion.div
                className="card text-white bg-dark mb-3 team-img"
                style={{ width: "25rem", overflow: "hidden" }}
                whileHover={{
                  boxShadow: "-2px 10px 25px rgb(0, 0, 0, 0.2)",
                  scale: 1.025,
                }}
              >
                <h3 style={{ textAlign: "center", color: "var(--white)" }}>
                  <motion.img
                    alt="img"
                    src={teamOne}
                    className="card-img-top"
                    whileHover={{
                      scale: 1.05,
                    }}
                  />
                </h3>

                <div className="card-body">
                  <h5 className="card-title" style={{ height: 40 }}>
                    Mr. King Fool
                    <br />
                    Co - Founder and Art Director
                  </h5>
                  <hr />
                  <h6 style={{ lineHeight: 1.4 }}>
                    King Fool is a successful entrepreneur in the private
                    sector, thrives in creativity driven solutions in which most
                    people may not see. Experience in launched brands in his
                    particular industry.
                  </h6>
                </div>
              </motion.div>
            </center>
          </div>
          <div className="col-sm" style={{ padding: "0 " }}>
            <center>
              <motion.div
                className="card text-white bg-dark mb-3 team-img"
                style={{ width: "25rem", overflow: "hidden" }}
                whileHover={{
                  boxShadow: "-2px 10px 25px rgb(0, 0, 0, 0.2)",
                  scale: 1.025,
                }}
              >
                <h3 style={{ textAlign: "center", color: "var(--white)" }}>
                  <motion.img
                    alt="img"
                    src={teamTwo}
                    className="card-img-top"
                    whileHover={{
                      scale: 1.05,
                    }}
                  />
                </h3>

                <div className="card-body">
                  <h5 className="card-title" style={{ height: 40 }}>
                    Mr. Fool
                    <br />
                    Co - Founder and Marketer
                  </h5>
                  <hr />
                  <h6 style={{ lineHeight: 1.4 }}>
                    Mr. Fool is a successful Real Estate entrepreneur with
                    analytical insights on Cryptocurrency, Stock Market and
                    NFTS. Experience in networking, communication and
                    understanding different angles of sales, buying patterns and
                    value creation
                  </h6>
                </div>
              </motion.div>
            </center>
          </div>
          <div className="col-sm" style={{ padding: "0 0" }}>
            <center>
              <motion.div
                className="card text-white bg-dark mb-3 team-img"
                style={{ width: "25rem", overflow: "hidden" }}
                whileHover={{
                  boxShadow: "-2px 10px 25px rgb(0, 0, 0, 0.2)",
                  scale: 1.025,
                }}
              >
                <h3 style={{ textAlign: "center", color: "var(--white)" }}>
                  <motion.img
                    alt="img"
                    src={teamThree}
                    className="card-img-top"
                  />
                </h3>

                <div className="card-body">
                  <h5 className="card-title" style={{ height: 40 }}>
                    Mr. Foolish
                    <br />
                    Co Founder and Director of Finances
                  </h5>
                  <hr />
                  <h6 style={{ lineHeight: 1.4 }}>
                    Mr Foolish is a successful entrepreneur in his space,
                    operations genius, financial strategist and the ability to
                    stay on track with the goals set from the team. Remaining
                    patient and achieving short term goals for the ultimate long
                    term milestones.
                  </h6>
                </div>
              </motion.div>
            </center>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
