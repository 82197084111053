import { animated, useSpring } from "react-spring";
import logo from "../assets/logo.png";
import useWindowDimensions from "../hooks/useWindowDimensions";
const Navigation = () => {
  const { width } = useWindowDimensions();

  const titleAnimation = useSpring({
    from: {
      color: "var(--white)",
      transform: "translateY(-200px)",
    },
    to: [{ color: "var(--white)", transform: "translateY(15px)" }],
    config: { mass: 2, tension: 190, friction: 22 },
  });

  const titleAnimation2 = useSpring({
    from: {
      color: "var(--white)",
      transform: "translateY(-200px)",
    },
    to: [{ color: "var(--white)", transform: "translateY(15px)" }],
    config: { mass: 2.2, tension: 180, friction: 22 },
  });

  const titleAnimation3 = useSpring({
    from: {
      color: "var(--white)",
      transform: "translateY(-200px)",
    },
    to: [{ color: "var(--white)", transform: "translateY(15px)" }],
    config: { mass: 2.4, tension: 170, friction: 22 },
  });

  const titleAnimation4 = useSpring({
    from: {
      color: "var(--white)",
      transform: "translateY(-200px)",
    },
    to: [{ color: "var(--white)", transform: "translateY(15px)" }],
    config: { mass: 2.6, tension: 160, friction: 22 },
  });

  const titleAnimation5 = useSpring({
    from: {
      color: "var(--white)",
      transform: "translateY(-200px)",
    },
    to: [{ color: "var(--white)", transform: "translateY(15px)" }],
    config: { mass: 2.8, tension: 150, friction: 22 },
  });

  const logoAnimation = useSpring({
    from: {
      height: "40px",
      opacity: 0,
    },
    to: [{ opacity: 1, height: "55px" }],
    config: { mass: 2.8, tension: 150, friction: 22 },
  });

  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark fixed-top justify-content-start"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        marginBottom: 40,
        paddingBottom: 30,
      }}
    >
      <div className="container fluid">
        <a
          className="navbar-brand d-flex w-50 me-auto"
          href="/"
          style={{ marginTop: 20 }}
        >
          <animated.img src={logo} alt="img" style={logoAnimation} />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapsingNavbar"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="navbar-collapse collapse w-100" id="collapsingNavbar">
          <ul
            className="navbar-nav w-50 justify-content-center"
            style={{ marginLeft: width > 992 && width < 1200 && 150 }}
          >
            <li className="nav-item">
              <a className="nav-link" href="#home">
                <animated.h6 style={titleAnimation}>
                  <div className="btn nav-btn">
                    <span>Home</span>
                  </div>
                </animated.h6>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#about">
                <animated.h6 style={titleAnimation2}>
                  <div className="btn nav-btn">
                    <span>About Us</span>
                  </div>
                </animated.h6>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#roadmap">
                <animated.h6 style={titleAnimation3}>
                  <div className="btn nav-btn">
                    <span>Roadmap</span>
                  </div>
                </animated.h6>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#team">
                <animated.h6 style={titleAnimation4}>
                  <div className="btn nav-btn">
                    <span>Team</span>
                  </div>
                </animated.h6>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#faq">
                <animated.h6 style={titleAnimation5}>
                  <div className="btn nav-btn">
                    <span>FAQ</span>
                  </div>
                </animated.h6>
              </a>
            </li>
          </ul>
          <ul
            className="nav navbar-nav ms-auto w-100 justify-content-end"
            style={{ marginTop: 20 }}
          >
            <li className="nav-item">
              <a
                href="https://twitter.com/FruityFools"
                target={"_blank"}
                className="btn"
                style={{ color: "white", fontSize: 20 }}
              >
                <i className="bi bi-twitter media-item"></i>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="btn"
                href="https://www.instagram.com/fruityfoolsnft/"
                target={"_blank"}
                style={{ color: "white", fontSize: 20 }}
              >
                <i className="bi bi-instagram media-item"></i>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="btn"
                href="https://discord.gg/fruityfools"
                target={"_blank"}
                style={{ color: "white", fontSize: 20 }}
              >
                <i className="bi bi-discord media-item" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
