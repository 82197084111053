import React from "react";
import Footer from "./Components/Footer";
import Navigation from "./Components/Navigation";
import FAQ from "./sections/FAQ";
import Home from "./sections/Home";
import Roadmap from "./sections/Roadmap";
import { ParallaxProvider } from "react-scroll-parallax";
import ImageParallax from "./Components/ImageParallax";
import Team from "./sections/Team";
import AboutUs from "./sections/AboutUs";

const App = () => {
  return (
    <div className="gradient-background">
      <ParallaxProvider>
        <Navigation />
        <Home />
        <AboutUs />
        <ImageParallax />
        <Roadmap />
        <Team />
        <FAQ />
        <Footer />
      </ParallaxProvider>
    </div>
  );
};

export default App;
