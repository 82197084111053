import React from "react";
import one from "../assets/1.png";
import six from "../assets/6.png";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useSpring, animated } from "react-spring";

const Home = () => {
  const { width, height } = useWindowDimensions();

  const calc = (x, y) => [x - width / 2, y - height / 2];
  const trans3 = (x, y) => `translate3d(${x / 6 - 200}px,${y / 6 - 200}px, 0)`;
  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 2, tension: 200, friction: 50 },
  }));

  const titleAnimation = useSpring({
    from: {
      opacity: 0,
      transform: "translateY(-120px)",
    },
    to: [
      {
        opacity: 1,
        transform: "translateY(15px)",
      },
    ],
    config: { mass: 3, tension: 300, friction: 25 },
  });

  return (
    <div
      id="home"
      style={{ position: "relative", height: "100vh" }}
      onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
    >
      <animated.img
        src={one}
        style={{
          height: width < 600 ? "320px" : "60vh",
          marginTop: width < 600 ? "260px" : "420px",
          marginLeft: width < 600 ? "10%" : "20%",
          transform: width > 600 && props.xy.to(trans3),
        }}
      />
      {width > 1600 && (
        <animated.img
          src={six}
          style={{
            height: width < 600 ? "320px" : "60vh",
            marginTop: width < 600 ? "100px" : "-540px",
            marginLeft: width < 600 ? "15%" : "70%",
            zIndex: 2,
            transform: width > 600 && props.xy.to(trans3),
          }}
        />
      )}

      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          marginTop: "50px",
          transform: "translate(-50%, -50%)",
        }}
      >
        <center>
          <animated.div style={titleAnimation}>
            <h1
              style={{
                textAlign: "center",
                color: "var(--white)",
                textShadow: "1px 1px 20px black",
              }}
            >
              Fancy an apple mate?
              <br />
              Behold for the very best on the marketplace,
              <br />
              <span style={{ fontSize: 60 }}>
                <strong>Fruity Fools!</strong>
              </span>
            </h1>
            <a href="https://mint.fruityfools.io" target="_blank">
              <button
                className="glow-on-hover"
                style={{
                  width: "300px",
                  height: "60px",
                  marginTop: 40,
                }}
                type="button"
              >
                MINT!
              </button>
            </a>
            <ul
              className="list-unstyled list-inline social text-center"
              style={{ marginTop: 60 }}
            >
              <li className="list-inline-item">
                <a
                  href="https://twitter.com/FruityFools"
                  target={"_blank"}
                  style={{
                    color: "white",
                    fontSize: 24,
                    marginRight: 10,
                    backgroundColor: "#111",
                    padding: 15,
                    borderRadius: "20%",
                  }}
                >
                  <i className="bi bi-twitter media-item"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  href="https://www.instagram.com/fruityfoolsnft/"
                  target={"_blank"}
                  style={{
                    color: "white",
                    fontSize: 24,
                    marginRight: 10,
                    backgroundColor: "#111",
                    padding: 15,
                    borderRadius: "20%",
                  }}
                >
                  <i className="bi bi-instagram media-item"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  href="https://discord.gg/fruityfools"
                  target={"_blank"}
                  style={{
                    color: "white",
                    fontSize: 24,
                    marginRight: 10,
                    backgroundColor: "#111",
                    padding: 15,
                    borderRadius: "20%",
                  }}
                >
                  <i className="bi bi-discord media-item" />
                </a>
              </li>
            </ul>
          </animated.div>
        </center>
      </div>
    </div>
  );
};

export default Home;
